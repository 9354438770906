import React, {useState, useEffect} from 'react';
import NavBar from '../components/Navbar';
import LandingHeader from '../components/LandingHeader';
import LandingPart2 from '../components/LandingPart2';
import Footer from '../components/Footer';
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: 'AKIA6GU5XYAN66ZO6K6I',
    secretAccessKey: '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',
    region: 'us-east-2',
});
const s3 = new AWS.S3();
  
const params = {
    Bucket: 'proeditor-home',
    Delimiter: '',
    Prefix: 'hong/',
};

//https://proeditor-home.s3.us-east-2.amazonaws.com/hana/
const LandingPage = () =>{

    const [listFiles, setListFiles] = useState([]);

    useEffect(()=>{
        s3.listObjectsV2(params, (err, data)=>{
            setListFiles(data.Contents);
            console.log("S3",data.Contents);
        }); 
    },[]);

    return(
        <div  style={{background:'#d8d4c9'}}>
            <NavBar/>
            <LandingHeader/>
            <LandingPart2/>
            <Footer/>
        </div>
    );

}
export default LandingPage