import React from 'react'; 
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import NavBar from '../components/Navbar'; 
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        marginTop: '15px', 
        width: '100%',
      },
    },
    cont:{
        fontSize:'20px',
        marginBottom:'15px',
        textAlign: 'center',
        color:'white'
    },
    contWrap:{
        marginTop:'10vh'
    },
    btnWrap:{
        marginTop: '15px'
    },
    sendBtn:{
        minWidth:'120px'
    },
    txt:{
      color:'white'
    }
  }));

export default function ContactPage() {

  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [value_mail, setValue_mail] = React.useState('');
  const [value_sub, setValue_sub] = React.useState('');

  const handleChange = (event) => {
      setValue(event.target.value);
  }; 

  const handleMail = (event) => {
    setValue_mail(event.target.value);
  };

  const handleSub = (event) => {
    setValue_sub(event.target.value);
};

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_seh14ni', 'template_1zth9js', e.target, 'user_HQ9y0jtCx1yBeDR4d4gpj')
      .then((result) => { 
          notify_success();
      }, (error) => { 
          notify_fail();
      });
  }

  const notify_success = () =>  toast.success(' Email was sent successfully!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const notify_fail = () =>  toast.error(' Email sent fail!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  return (
    <div style={{background:'#d8d4c9'}}>  
    {/* //3d3c35 */}
      <NavBar/>     
      <div style={{background:'#3d3c35'}}>
        <Container className={classes.contWrap, 'conbody'}  maxWidth="sm">
        
          <Grid item  xs={12}>
            <p className={classes.cont}>Do you have a question about the ProEditor?<br/> Check out our FAQ and Help Center</p>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={sendEmail}>  
                <div>
                    <TextField name="c_name" className={classes.txt} label="Name" placeholder="Placeholder" value={value} onChange={handleChange} variant="outlined" />
                </div>
                <div>
                    <TextField name="c_email" className={classes.txt} label="Email" placeholder="Placeholder" value={value_mail} onChange={handleMail} variant="outlined" />
                </div>
                <div>
                    <TextField name="c_subject" className={classes.txt} label="Subject" placeholder="Placeholder" value={value_sub} onChange={handleSub} variant="outlined" />
                </div>
                <div>
                    <TextField name="c_message" className={classes.txt} label="Message" multiline rows={6} placeholder="Placeholder" variant="outlined" />
                </div>
                <div className={classes.btnWrap}>
                    <Button className={classes.sendBtn} variant="outlined" color="primary" type="submit">Send</Button>
                </div>
            </form>         
          </Grid>      
        </Container>   
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        <ToastContainer />   
      </div>
      <Footer />
      {/* style={{background:'#3d3c35'}} */}
    </div>
  );
}