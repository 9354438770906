import './App.css';
import MyRouters from './routers/Routers';

function App() {
  return (
    <div>
      <MyRouters/>
    </div>
  );
}

export default App;
