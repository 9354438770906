import React, {useState, useEffect} from 'react'; 
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';  
// import GoogleButton from 'react-google-button';
import { AppleLoginButton, GoogleLoginButton } from "react-social-login-buttons";

import getFirebase  from '../components/firebase/firebase'; 
import useInput from '../components/Input';
import firebase from "firebase";
import { useHistory } from "react-router-dom"; 
import QrCode from '../components/QrCode';
import sha256 from 'crypto-js/sha256';
import logos from '../images/logoblack.png';

const publicIp = require('public-ip');

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.proeditor.io">
          ProEditor.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}
  
const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: '50px',
      fontSize:'16px',
      textTransform:'none !important',       
    },
    switches:{
      textDecoration:'none',
      cursor:'pointer',
    },  
    logos:{
      width:'100px',
      cursor:'pointer'
    }  
})); 

const SignInPage = () =>{     

    const classes = useStyles();
    const [wantSignIn, setWant] = useState(true);

    const firebaseInstance = getFirebase();    
    const email = useInput("");
    const password = useInput("");
    let history =useHistory();

    const Login = () =>{
      history.push("/");
    }

    const keepUser = (docId, email, uId, paid_date, ticket, paid_type) => {
       //console.log(refId,"--", email,"--", uId,"--", paid_date,"--", ticket,"--", paid_type);
       localStorage.setItem('login', 'true');
       localStorage.setItem('docId', docId);
       localStorage.setItem('userId', uId);
       localStorage.setItem('email', email);
       localStorage.setItem('paid_date', paid_date);
       localStorage.setItem('paid_type',paid_type);
       localStorage.setItem('ticket',ticket); 
       Login(); 
    }

    const addUser = async(email, uid) =>{
      var userData = firebaseInstance.firestore().collection('/users');
      await userData.add({
        app: 2,           // app 1 web 2
        email: email,
        paid_date: 0,
        tickets: 5,
        type: 0,
        uid: uid,
      }).then((docRef) =>{
        console.log(docRef.id);
        keepUser(docRef.id, email, uid, 0, 5, 0);
      }).catch((err) =>{
        console.log(err);
      });
    }

    const readUser = async(uid) => {
      var userData = firebaseInstance.firestore().collection('/users');
      await userData.where("uid","==", uid).get().then(function(snapshot) {
        snapshot.forEach(element => {
          keepUser(element.id, element.data().email, element.data().uid, element.data().paid_date, element.data().tickets, element.data().type);          
        });
      })
    }
    
    //sign up with email
    const signUp = async(event) => {
      event.preventDefault();
      try{
        if(firebaseInstance){         
          await firebaseInstance.auth().createUserWithEmailAndPassword(email.value, password.value).then((result) =>{
            console.log(result.user.email);
            console.log(result.user.uid);
            addUser(result.user.email, result.user.uid);
          }).catch((e)=>{
            if(e.code ==='auth/weak-password'){
              alert("Weak password! Please use strong password.");
            }else if(e.code === 'auth/email-already-in-use'){
              alert("Email already is in using! Please use another email.");
            }else if(e.code === 'auth/invalid-email'){
              alert("Invalid email! Please use another email.");
            }else{
              alert("Unknown error! Please try again.");
            }            
          });          
        }
      }catch(e){
        //console.log("error", e);
      }
    }
    
    //sign in with email
    const signIn = async(event) =>{
      event.preventDefault();
      try{
        if(firebaseInstance){
           await firebaseInstance.auth().signInWithEmailAndPassword(email.value, password.value).then((result) =>{            
            readUser(result.user.uid);           
           }).catch((e) =>{
            if(e.code ==='auth/user-disabled'){
              alert("Sorry, you are disabled! Please contact support team.");
            }else if(e.code === 'auth/user-not-found'){
              alert("Sorry, we didn't find you. Please sign up.");
            }else if(e.code === 'auth/invalid-email'){
              alert("Invalid email! Please use another email.");
            }else if(e.code === 'auth/wrong-password'){
              alert("Wrong password! Forget it?");
            }else{
              alert("Unknown error! Please try again.");
            }
           }); 
        }
      }catch(e){
        console.log("error", e);
      }
    } 

    //sign in with google auth
    const signWithGoogle = async() =>{     
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      await firebaseInstance.auth().signInWithPopup(provider).then((result)=>{
        if(result.additionalUserInfo.isNewUser){
          addUser(result.user.email, result.user.uid);   //new user sign up
        }else{         
          readUser(result.user.uid);     //old user sign in 
        }         
      }).catch((e) =>{
        console.log("error", e);
      })
    }

  
    
    
    //sign in with phone
    // const signWithPhone = async(event) => {     
    //   var applicationVerifier =  new firebase.auth.RecaptchaVerifier('recaptcha-container'); 
    //   await firebaseInstance.auth().signInWithPhoneNumber(email.value, applicationVerifier).then(function(confirmResult){
    //     var verificationCode = window.prompt('Please enter the verification ' + 'code that was sent to your mobile device.');
    //     return confirmResult.confirm(verificationCode);
    //   }).catch(function(error){

    //   });
    // }

    //reset password
    // const resetPassword = async() =>{
    //   var actionCodeSettings = {
    //     url: 'https://www.example.com/?email=user@example.com',
    //     iOS: {
    //       bundleId: 'com.example.ios'
    //     },
    //     android: {
    //       packageName: 'com.example.android',
    //       installApp: true,
    //       minimumVersion: '12'
    //     },
    //     handleCodeInApp: true
    //   }
    //   firebaseInstance.auth().sendPasswordRestEmail(email.value, actionCodeSettings).then(function(){
    //     console.log("password reset email sent");
    //   }).catch((e) =>{
    //     console.log("error", e);
    //     if(e.code == 'auth/invalid-email'){
    //       console.log("invalid email");
    //     }
    //   })
    // }
    
    
    

    //get data firebase firestore database
    // const getData = async() =>  {
    //   var uuid = "5VA0mWC5pXSEmsGQVlrA5BborjN2";
    //   var userData = firebaseInstance.firestore().collection('/users');
    //   userData.where("uid","==", uuid ).get().then(function(snapshot) {
    //     snapshot.forEach(element => {
    //       var id = element.id;
    //       var email = element.data().email;
    //       console.log(email);
    //     });
    //   })

    //   userData.get().then(function(snapshot) {
    //     snapshot.forEach(element => {
    //       var id = element.id;
    //       var email = element.data().email;
    //       console.log(email);
    //     });
    //   })

    //   userData.onSnapshot((snapshot)=>{
    //     snapshot.docChanges().forEach((change) => {
    //       if(change.type === 'added'){
    //         console.log("New", change.doc.data().email);
    //       }
    //       if(change.type === 'modified'){
    //         console.log("Modified", change.doc.data().email);
    //       }
    //     })
    //   })  
    // }

    //add data firebase firestore database
    // const addData = async() => {
    //   var userData = firebaseInstance.firestore().collection('/users');
    //   await userData.add({
    //     app: 1,
    //     email:'hanna@mail.com',
    //     paid_date: 231,
    //     tickets: 4,
    //     type: 2,
    //     uid: 'ssssssssssss',
    //   }).then((docRef) =>{
    //     console.log(docRef.id);
    //   }).catch((err) =>{
    //     console.log(err);
    //   });
    // }

    //update data firebase firestore database
    // const updateData = async() =>{
    //   var userData = firebaseInstance.firestore().collection('/users');
    //   await userData.doc('fEhARhukqqE3wXNfY9VF').update({
    //     type:34
    //   });
    // }
  
    //delete data firebase firestore database
    // const deleteData = async() =>{
    //   var userData = firebaseInstance.firestore().collection('/users');
    //   await userData.doc('fEhARhukqqE3wXNfY9VF').delete();
    // }

    function changeWant() {
      setWant(!wantSignIn);       
    }
    


    const [id, setID] = useState("");
    useEffect(async() => {    
      const ip = await publicIp.v4();    
      const stamp =  Date.now() 

      var deviceData = firebaseInstance.firestore().collection('/device');
      let doc_id = "";
      await deviceData.where("ip","==", ip).get().then(function(snapshot) {
        let cnt = 0;
        snapshot.forEach(element => {
          cnt++;
          deviceData.doc(element.id).update({
             login : 'false',
             stamp : stamp,
             data:'',
          })        
          doc_id = element.id;             
        });
        if(cnt == 0){ 
              deviceData.add({
              ip:ip,              
              login: 'false',
              stamp: stamp, 
              data:'',
            }).then((docRef) =>{               
              doc_id = docRef.id;
            }).catch((err) =>{
              console.log(err);
            });
        }

        deviceData.doc(doc_id)
        .onSnapshot({            
            includeMetadataChanges: true
        }, (doc) => {
            console.log(doc.data().login);
            if(doc.data().login == "true"){
              console.log("login");
              console.log(doc.data().data);
              readUser(doc.data().data);
            }
        });

      }) 
      setID( 'proeditor`'+doc_id+"`"+ ip + "`" + stamp);
     
    }, [])

    const Homes = ()=>{
      history.push("/");
    }

    // const responseGoogle = (response) => {
    //   console.log(response);
    // }

    return(
        <>
            {/* <NavBar/> */}
            <div>                
                <Grid container component="main" className={classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <div className={classes.paper}>
                             
                             <img className={classes.logos} src={logos} onClick={Homes}/>
                             
                            {/* <Typography component="h1" variant="h5">
                                {wantSignIn===true? 'Sign in' : 'Sign up'}
                            </Typography>
                            <form className={classes.form} noValidate >
                                <TextField {...email} variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus/>
                                <TextField {...password} variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                                <FormControlLabel control={<Checkbox value="remember" color="primary" />}label="Remember me"/>
                                {wantSignIn === true? <Button onClick={signIn} type="button" fullWidth variant="contained" color="primary" className={classes.submit}>Sign in with Email</Button>: 
                                <Button onClick={signUp} type="button" fullWidth variant="contained" color="primary" className={classes.submit}>Sign up with Email</Button>}     
                            </form>                             */}
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2"> {wantSignIn === true? "Forgot password?":" "}</Link>
                                </Grid>
                                <Grid item>
                                    <Link className={classes.switches} variant="body2"  onClick={changeWant}> {wantSignIn === true? "Don't have an account? Sign Up":"I have already account."} </Link>
                                </Grid>
                            </Grid>    */}
                            {/* <Box className={'wrap_google_btn'} mt={5}> <GoogleButton  onClick={() => { console.log('Google button clicked') }}/> </Box> 
                             <Box className={'wrap_social_btn'} mt={8}> <GoogleLoginButton onClick={signWithGoogle} /> </Box>
                             <Box className={'wrap_social_btn'} mt={1}> <AppleLoginButton onClick={()=>{console.log("");}} /> </Box> */}
                            <div>
                              <QrCode id={id}/>
                            </div>
                            <Box mt={5}><Copyright /></Box>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default SignInPage

// https://www.npmjs.com/package/react-social-login-buttons
// https://www.npmjs.com/package/react-apple-login
// npm install --save firebase

// yarn add firebase
//yarn add @react-firebase/auth
