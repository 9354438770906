import React from 'react'; 
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom"; 
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import logoblack from '../images/logoblack.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.proeditor.io/">
        ProEditor.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  }, 
  footer: { 
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },    
    textAlign:'center'
  },
  footer_logo:{
    maxWidth:'80px'
  },
  footer_link:{
    '&:hover': {
      cursor:'pointer'
    } 
  }
}));
 
const footers = [
  {
    title: 'Terms of Use',
    linkId : 1,     
  },
  {
    title: 'Privacy Policy',
    linkId : 2,
  },
  {
    title: 'Contact Us',
    linkId : 3,
  }, 
];

export default function Footer() {
  const classes = useStyles();
  let history =useHistory();

  const goPage = (id)=>{
    if(id === 1) 
     history.push("/term_of_use");
    if(id === 2) 
     history.push("/policy");
    if(id === 3) 
     history.push("/contact_us");
  }

  return (
    <div>       
      <Container   maxWidth="md" component="footer" className={classes.footer}>
        <img src={logoblack} className={classes.footer_logo}/>
        <Box mt={1} mb={2}>
          <Copyright />
        </Box>
        <Grid container spacing={4} justify="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography gutterBottom style={{fontWeight:'100', color:'#181818'}} className={classes.footer_link} onClick={()=>goPage(footer.linkId)}>
                {footer.title}
              </Typography>               
            </Grid>
          ))}
        </Grid>        
      </Container>       
    </div>
  );
}