import firebase from "firebase";
require('dotenv').config();

// If you're not using Code Sandbox, never hard-code the keys! Add them in your .env file and link them here
// var firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_MID
// };
 
var firebaseConfig = {
  apiKey: "AIzaSyDKmFyiDsqSq3k11MI1BwSC6Xz6kPNK3T4",
  authDomain: "proeditor620.firebaseapp.com",
  projectId: "proeditor620",
  storageBucket: "proeditor620.appspot.com",
  messagingSenderId: "931709150028",
  appId: "1:931709150028:web:4c9ad063521d5a6def65e9",
  measurementId: "G-WQ5FWRGE64"
};




 
// Initialize Firebase
let instance;

 const getFirebase = ()=>{

  console.log("APIKEY", process.env.REACT_APP_FIREBASE_API_KEY);
  
  if (typeof window !== "undefined") {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }
} 

export default getFirebase;

 
 