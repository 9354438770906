import React from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppStore from '../images/applestore.png';
import GooglePlay from '../images/googleplay.png';
import phone from '../images/phone.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#3d3c35 !important',
    
  }, 
  hbody:{
    marginTop: '4em',
    marginBottom: '2em',
  },
  txt_title:{
      fontSize:50,
      color:'white',
      fontWeight:700,
  },
  txt_down:{
    fontSize:20,
    color:'white',  
    fontWeight:200,   
  },
  align_apple:{
    textAlign:'right',
  },
  align_google:{
    textAlign:'left',
  },
  img_store:{
      width:'65%', 
      cursor:'pointer',
      
  },
  
  
}));

export default function LandingHeader() {
  const classes = useStyles();

  console.log(AppStore);

  const goGoogle = () =>{
    var btn = document.createElement("a");  
    btn.href="https://play.google.com/store/apps/details?id=image.proeditor.proeditor";
    btn.setAttribute('target', '_blank');
    btn.click();
  }
  const goApple = () =>{
    var btn = document.createElement("a");  
    btn.href="https://apps.apple.com/us/app/proeditor-create-studio-photo/id1581864512#?platform=iphone";
    btn.setAttribute('target', '_blank');
    btn.click();
  }

 



  return (
    <div className={classes.root}>
    <Container  maxWidth="lg">
      <Grid  container spacing={3}>        
        <Grid className={classes.hbody} item xs={12} sm={6}>
           <p className = {classes.txt_title}>Automatically<br/> create professional<br/> studio photos.</p>
           <p className = {classes.txt_down}>Download the app.  It's free</p>
           <div>
           <Grid container item xs={12} spacing={2}>                
              <Grid className={classes.align_apple} item xs={8} sm={6}>
               <div style={{display:'flex'}}>
                <img className={classes.img_store} src={AppStore} onClick={()=>goApple()} alt="ProEditor"/> 
                <img style={{marginLeft:'5%'}} className={classes.img_store} onClick={()=>goGoogle()} src={GooglePlay} alt="ProEditor"/> 
               </div>                
              </Grid>
              <Grid className={classes.align_google} item xs={6} sm={5}> 
              </Grid>
           </Grid>            
           </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{textAlign:'center'}}>
           <img src={phone}/>
        </Grid>        
      </Grid>
      </Container>
    </div>
  );
}
