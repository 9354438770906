import React from 'react';
import { makeStyles } from '@material-ui/core/styles'; 
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import videofile from '../images/file.mp4'; 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: '#3d3c35 !important',
    paddingTop:'5vh',
    paddingBottom:'10vh',
    
  }, 
  hbody:{
    marginTop: '5em',
    marginBottom: '2em',
  },
  txt_title:{
      fontSize:50,
      color:'white',
      fontWeight:700,
  },
  txt_down:{
    fontSize:20,
    color:'white',  
    fontWeight:200,   
  }, 
  
}));

export default function LandingHeader() {
  const classes = useStyles(); 
  return (
    <div className={classes.root}>
    <Container  maxWidth="lg">
      <Grid  container spacing={3} style={{display:'flex'}}>   
        <Grid className={classes.hbody}  item xs={12} sm={6} style={{textAlign:'center', display:'flex'}}> 
           <video width="100%" height="auto"  controls  autoPlay  src={videofile} />
        </Grid>      
        <Grid item xs={12} sm={6} style={{paddingLeft:'5%', margin:'auto'}}>
           <p className = {classes.txt_title}>View and Download Photos On Your Computer.</p>
           <p className = {classes.txt_down}>After editing on the ProEditor APP, you can store the photo on our website: proeditor.io, use the scan function on the APP to scan the QR code, you can directly view and download your photos.</p>            
        </Grid>               
      </Grid>
      </Container>
    </div>
  );
}
