import React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import AlbumPage from '../pages/AlbumPage';
import ContactPage from '../pages/ContactPage';
import LandingPage from '../pages/LandingPage';
import PricePage from '../pages/PricePage';
import SignInPage from '../pages/SigninPage';
import AdminPage from '../pages/AdminPage';
import UpgradePage from '../pages/UpgradePage';
import PolicyPage from '../pages/PolicyPage';
import TermPage from '../pages/TermPage';


export default function MyRouters(){
  return (
      <Router>
          <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/sign_in" component={SignInPage} />
              <Route path="/my_account" component={AlbumPage} />
              <Route path="/price_info" component={PricePage} />
              <Route path="/contact_us" component={ContactPage} />
              <Route path="/policy" component={PolicyPage} />
              <Route path="/term_of_use" component={TermPage} />
              <Route path="/admin_io" component={AdminPage} />
              <Route path="/upgrade/:uid"  component={UpgradePage} /> 
          </Switch>
      </Router>
  );
}