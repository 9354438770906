import React, {useState, useEffect} from 'react';  
import Button from '@material-ui/core/Button'; 
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia'; 
import Grid from '@material-ui/core/Grid'; 
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'; 
 


//import AWS from 'aws-sdk';

// AWS.config.update({
//     accessKeyId: 'AKIA6GU5XYAN66ZO6K6I',
//     secretAccessKey: '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',
//     region: 'us-east-2',
// });
// const s3 = new AWS.S3();
 
// const params = {
//     Bucket: 'proeditor-home',
//     Key:'gmNVszxAfRlrYe9FHWMA/112-1127685_csg-custom-software-development-custom-software-development-hd.png',
//     // Prefix: 'gmNVszxAfRlrYe9FHWMA/',
// }; 

// s3.deleteObject(params, function(err, data) {
//     if (err) {
//       console.log("Error", err);
//       console.log("ghost............");
//     } else {
//         console.log("yyyyyyyyyyyy---------------");
//       console.log("Success", data);
//     }
// });



const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
     
}));

export default function Album(props) {
  const classes = useStyles();
  const [cards, setUrl] = useState([]);  
  useEffect(() => {   
    setUrl(props.data);
    console.log("PROP", props.data);
  }, [props])

   
  function downImage(filename){
    let imageUrl = 'https://proeditor-home.s3.us-east-2.amazonaws.com/'+ filename;
    console.log("IMAGE",filename);
    const axios = require('axios');
      axios({
        method: "get",
        url: imageUrl,
        responseType: "arraybuffer"
      })
      .then((response) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" })
        );
        link.download = filename;
    
        document.body.appendChild(link);
    
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch((error) => {});
  }

  return (
    <div>  
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={2}>
            {cards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={'https://proeditor-home.s3.us-east-2.amazonaws.com/'+cards[index]['Key']}
                    title="Image title"
                  /> 
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h4">                       
                    </Typography> 
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" onClick={() => props.openDialog(true, cards[index]['Key'])}>
                      View
                    </Button>
                    <Button onClick={() => downImage(cards[index]['Key'])} size="small" color="primary">
                      Download
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>   
    </div>
  );
} 