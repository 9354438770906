import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader'; 
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder'; 
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';  
import { useHistory } from "react-router-dom";   
import getFirebase  from '../components/firebase/firebase'; 
import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },   
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },   
}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
    myRoute:'/sign_in',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '15',
    description: [
      '20 users included',
      '10 GB of storage',
      'Help center access',
      'Priority email support',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
    myRoute:'/',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
    myRoute:'/sign_in',
  },
]; 

export default function UpgradePage(props) {
  const classes = useStyles();
  let history =useHistory();
  const firebaseInstance = getFirebase();    

  const [isLogin, loadLogin] = useState('false');  
  const [docId, loadDocId] = useState('');
  const [email, loadEmail] = useState('');
  const [greet, loadGreet] = useState('Hi');  

  useEffect (() => {
    console.log(props);
    const param = props.match.params;
    readUser(param.uid);   
    
    var data = [
        [22, 'Working late,'],
        [18, 'Good evening,'],
        [12, 'Good afternoon,'],
        [5,  'Good morning,'],
        [0,  'Whoa, early bird!']
    ],
    hr = new Date().getHours();
    for (var i = 0; i < data.length; i++) {
        if (hr >= data[i][0]) {
            loadGreet(data[i][1]); 
            break;
        }
    }     
  },[]) 

  const started = (myRoute) =>{
    history.push(myRoute);
  }

  const readUser = async(uid) => {
    var userData = firebaseInstance.firestore().collection('/users');
    await userData.where("uid","==", uid).get().then(function(snapshot) {
      snapshot.forEach(element => {
        keepUser(element.id, element.data().email, element.data().uid, element.data().paid_date, element.data().tickets, element.data().type);          
      });
    })
  }

  const keepUser = (docId, email, uId, paid_date, ticket, paid_type) => { 
    localStorage.setItem('login', 'true');
    localStorage.setItem('docId', docId);
    localStorage.setItem('userId', uId);
    localStorage.setItem('email', email);
    localStorage.setItem('paid_date', paid_date);
    localStorage.setItem('paid_type',paid_type);
    localStorage.setItem('ticket',ticket);  
    console.log("GOOD JOB");
    if(localStorage.getItem('login') === 'true'){
      loadLogin(localStorage.getItem('login'));      
      loadDocId(localStorage.getItem('docId'));
      loadEmail(localStorage.getItem('email'));  
    }else{
      console.log("not login");
    }
 }

  return (
    <div>    
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
          Price & Plans
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
           {isLogin === 'true'? greet+" Thank you for using our service.":greet+" Please sign in for the next step."}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={()=>started(tier.myRoute)}>
                    {tier.buttonText} 
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>  
    </div>
  );
}