import React from 'react'; 
import { makeStyles } from '@material-ui/core/styles'; 
import NavBar from '../components/Navbar';
import { useHistory } from "react-router-dom"; 
import Footer from '../components/Footer';  
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BarChartIcon from '@material-ui/icons/BarChart';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EmailIcon from '@material-ui/icons/Email';
 

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 5,
      paddingLeft:'2%',
      paddingRight:'2%',
    },
    paper: {
      padding: theme.spacing(10),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    wrap_padding:{
        padding: theme.spacing(18),
    },
    contentLeft:{
        paddingLeft:'2%',
        margin:'0'
    },
    miniTitleLeft:{
        fontWeight:'500',
        paddingLeft:'2%',
        margin:'0'
    },
    miniTitleLeftNo:{
        fontWeight:'500', 
        margin:'0'
    },
    fontW500:{
        fontWeight:'500',
    },
    iconWrap:{        
        fontSize:'2em',
        top:'6px',
        position:'relative'
    },
    margin0:{
        margin:'0'
    },
    divider:{        
        borderBottom:'1px dotted #DFDFDF',
        paddingBottom:'10px'  
    }

  }));

 

export default function PolicyPage() {
  const classes = useStyles();
  let history = useHistory(); 

  const started = (myRoute) =>{
    history.push(myRoute);
  }

  return (
    <div>   
      <NavBar/>
      <div className={classes.root}>
       <Grid container spacing={3}>
         <Grid item sm={1} xm={0}></Grid>  
         <Grid  item xs={12} sm={10} className={classes.wrap_padding}> 
           
            <h3>Privacy Policy of ProEditor</h3>
            <p>This Application collects some Personal Data from its Users.</p>
            <p>This document can be printed for reference by using the print command in the settings of any browser.</p>
            <br/>
            <h3 style={{textAlign:'center', borderBottom:'1px dotted #DFDFDF', borderTop:'1px dotted #DFDFDF', padding:'20px'}}>Policy summary</h3>
            <p style={{fontWeight:'bold'}}>Personal Data collected for the following purposes and using the following services:</p>
            
            <p className={classes.fontW500}><BarChartIcon className={classes.iconWrap}/> Analytics</p>
              <p className={classes.miniTitleLeft}> Amplitude</p> 
              <p className={classes.contentLeft}>Personal Data: Cookies; Usage Data</p>
              <br/>
              <p className={classes.miniTitleLeft}> Google Analytics for Firebase</p> 
              <p className={classes.contentLeft}>Personal Data: Application opens; Application updates; device information; first launches; geography/region; In-app purchases; number of sessions; number of Users ; operating systems; session duration; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data</p>
              <br/>
              <p className={classes.miniTitleLeft}> Facebook Analytics for Apps</p> 
              <p className={classes.contentLeft}>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</p>
            
            <p className={classes.fontW500}><TurnedInNotIcon className={classes.iconWrap}/> Beta Testing</p>
              <p className={classes.miniTitleLeft}> TestFlight</p> 
              <p className={classes.contentLeft}>Personal Data: app information; country; Data communicated while using the service</p>
              <br/>

            <p className={classes.fontW500}><TurnedInNotIcon className={classes.iconWrap}/> Device permissions for Personal Data access</p>
              <p className={classes.miniTitleLeft}> Device permissions for Personal Data access</p> 
              <p className={classes.contentLeft}>Personal Data: Camera permission; Photo Library permission; Write-only Photo Library permission</p>
              <br/>
              <p className={classes.miniTitleLeft}> Device permissions for TrueDepth Personal Data</p> 
              <br/>  

            <p className={classes.fontW500}><TurnedInNotIcon className={classes.iconWrap}/> Infrastructure monitoring</p>
              <p className={classes.miniTitleLeft}> Crashlytics</p> 
              <p className={classes.contentLeft}>Personal Data: geographic position; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); various types of Data as specified in the privacy policy of the service</p>
              <br/>  

            <p className={classes.fontW500}><TurnedInNotIcon className={classes.iconWrap}/> User database management</p>
              <p className={classes.miniTitleLeft}> Intercom</p> 
              <p className={classes.contentLeft}>Personal Data: Cookies; email address; Usage Data; various types of Data as specified in the privacy policy of the service</p>
              <br/>  

            <p style={{fontWeight:'bold'}}>Contact information</p>  
            <p className={classes.fontW500}><EmailIcon className={classes.iconWrap}/> Owner and Data Controller</p>
              <p className={classes.miniTitleLeft}> Eason and Song</p> 
              <p className={classes.contentLeft}>Owner contact email: support@proeditor.io</p>
              <br/> 

            <h3 style={{textAlign:'center', borderBottom:'1px dotted #DFDFDF', borderTop:'1px dotted #DFDFDF', padding:'20px'}}>Full policy</h3>  
            <p style={{fontWeight:'bold'}}>Owner and Data Controller</p>   
              <p className={classes.miniTitleLeft}> Eason and Song</p> 
              <p className={classes.contentLeft}>Owner contact email: support@proeditor.io</p>              
            <p className={classes.divider}></p>  

            <p style={{fontWeight:'bold'}}>Types of Data collected</p>
            <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); device information; geography/region; number of Users ; number of sessions; session duration; In-app purchases; Application opens; Application updates; first launches; operating systems; email address; Data communicated while using the service; country; app information; geographic position; Photo Library permission; Write-only Photo Library permission; Camera permission.</p>   
            <p>Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.
Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.</p>
            <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.</p>
            <p className={classes.divider}></p>  

            <p style={{fontWeight:'bold'}}>Mode and place of processing the Data</p>
            <p className={classes.miniTitleLeftNo}>Methods of processing</p> 
            <p>The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.</p>
             
            <p className={classes.miniTitleLeftNo}>Legal basis of processing</p>  
            <p>The Owner may process Personal Data relating to Users if one of the following applies:</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} /> Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} /> provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} /> processing is necessary for compliance with a legal obligation to which the Owner is subject;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} /> processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} /> processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.</p>
            <p>In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p> 
 
            <p className={classes.miniTitleLeftNo}>Place</p>  
            <p>The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.</p>
            <p>Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.</p>
            <p>Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.</p>
            <p>If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.</p>
 
            <p className={classes.miniTitleLeftNo}>Retention time</p> 
            <p>Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.</p>
            <p>Therefore:</p>
            <p><ArrowRightIcon style={{fontSize:'medium'}} /> Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.</p>
            <p><ArrowRightIcon style={{fontSize:'medium'}} /> Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.</p>
            <p>The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.</p>
            <p>Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.</p>
            <p className={classes.divider}></p>   
 
            <p style={{fontWeight:'bold'}}>The purposes of processing</p> 
            <p>The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, User database management, Beta Testing, Infrastructure monitoring and Device permissions for Personal Data access.</p>
            <p>For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.</p>
            <p className={classes.divider}></p>  

            <p style={{fontWeight:'bold'}}>Device permissions for Personal Data access</p> 
            <p>Depending on the User's specific device, this Application may request certain permissions that allow it to access the User's device Data as described below.</p>
            <p>By default, these permissions must be granted by the User before the respective information can be accessed. Once the permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings or contact the Owner for support at the contact details provided in the present document.
The exact procedure for controlling app permissions may be dependent on the User's device and software.</p>
            <p>Please note that the revoking of such permissions might impact the proper functioning of this Application.</p>
            <p>If User grants any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by this Application.</p>
             
            <p className={classes.miniTitleLeftNo}>Camera permission</p>  
            <p>Used for accessing the camera or capturing images and video from the device.</p>
            <p className={classes.miniTitleLeftNo}>Photo Library permission</p>  
            <p>Allows access to the User's Photo Library.</p>
            <p className={classes.miniTitleLeftNo}>Write-only Photo Library permission</p>  
            <p>Allows write-only access to the User's photo library.</p>

            <br/>
            <br/>
            <p>ProEditor hosts this content and only collects the Personal Data strictly necessary for it to be provided.</p>




         </Grid>
         <Grid item sm={1} xm={0}></Grid>  
       </Grid>
      </div> 
      <Footer/>
    </div>
  );
}