import React from 'react'; 
import { makeStyles } from '@material-ui/core/styles'; 
import NavBar from '../components/Navbar';
import { useHistory } from "react-router-dom"; 
import Footer from '../components/Footer';  
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BarChartIcon from '@material-ui/icons/BarChart';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import EmailIcon from '@material-ui/icons/Email';
 

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 5,
      paddingLeft:'2%',
      paddingRight:'2%',
    },
    paper: {
      padding: theme.spacing(10),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    wrap_padding:{
        padding: theme.spacing(18),
    },
    contentLeft:{
        paddingLeft:'2%',
        margin:'0'
    },
    miniTitleLeft:{
        fontWeight:'500',
        paddingLeft:'2%',
        margin:'0'
    },
    miniTitleLeftNo:{
        fontWeight:'500', 
        margin:'0'
    },
    fontW500:{
        fontWeight:'500',
        fontSize:'20px'
    },
    fontW400:{
        fontWeight:'500',
        fontSize:'18px',
        color : '#333333'
    },
    iconWrap:{        
        fontSize:'2em',
        top:'6px',
        position:'relative'
    },
    margin0:{
        margin:'0'
    },
    divider:{        
        borderBottom:'1px dotted #DFDFDF',
        paddingBottom:'10px'  
    }

  }));

 

export default function TermPage() {
  const classes = useStyles();
  let history = useHistory(); 

  const started = (myRoute) =>{
    history.push(myRoute);
  }

  return (
    <div>   
      <NavBar/>
      <div className={classes.root}>
       <Grid container spacing={3}>
         <Grid item sm={1} xm={0}></Grid>  
         <Grid  item xs={12} sm={10} className={classes.wrap_padding}> 
           
            <h3>Terms and Conditions of ProEditor</h3> 
            <p>These Terms govern</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />the use of this Application, and ,</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />any other related Agreement or legal relationship with the Owner in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</p>
              
            <p>The User must read this document carefully.</p>
            <h3 style={{textAlign:'center', borderBottom:'1px dotted #DFDFDF', padding:'20px'}}> </h3>
            <p>Although the entire contractual relationship relating to these Products is entered into solely by the Owner and Users, Users acknowledge and agree that, where this Application has been provided to them via the Apple App Store, Apple may enforce these Terms as a third-party beneficiary.</p>
            <p>This Application is provided by:</p>
            <p>518, Laser Road, Ouhai District, Zhejiang Province, China</p>
            <p>Owner contact email: support@proeditor.com</p>
            <br/>
            <h3>What the User should know at a glance</h3> 
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Usage of this Application and the Service is age restricted: to access and use this Application and its Service the User must be an adult under applicable law.</p>
              <h3 style={{textAlign:'center', borderTop:'1px dotted #DFDFDF', padding:'20px'}}> </h3>
            
            <h3>TERMS OF USE</h3> 
            <p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application. Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
            <p>By using this Application, Users confirm to meet the following requirements:</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />There are no restrictions for Users in terms of being Consumers or Business Users;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Users must be recognized as adult by applicable law;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Users aren’t located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist-supporting” country;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Users aren’t listed on any U.S. Government list of prohibited or restricted parties;</p>
           
            <p className={classes.fontW500}>Account registration</p>
              <p>To use the Service Users may register or create a User account, providing all required data or information in a complete and truthful manner. Users may also use the Service without registering or creating a User account, however, this may cause limited availability of certain features or functions.</p>
              <p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.</p>
              <p>By registering, Users agree to be fully responsible for all activities that occur under their username and password. Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
            
             <p className={classes.fontW400}>Conditions for account registration</p>
              <p>Registration of User accounts on this Application is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Accounts registered by bots or any other automated methods are not permitted.</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Unless otherwise specified, each User must register only one account.</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />Unless explicitly permitted, a User account may not be shared with other persons.</p>
             <p className={classes.fontW400}>Account termination</p>
              <p>Users can terminate their account and stop using the Service at any time by doing the following:</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />By directly contacting the Owner at the contact details provided in this document.</p>
             <p className={classes.fontW400}>Account suspension and deletion</p>
              <p>The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms. The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement. The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
                        
            <p className={classes.fontW500}>Content on this Application</p>  
              <p>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</p>
              <p>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result. In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
             <p className={classes.fontW400}>Rights regarding content on this Application - All rights reserved</p>
              <p> The Owner holds and reserves all intellectual property rights for any such content. Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service. In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge. Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented. Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>

            <p className={classes.fontW500}>Content provided by Users</p>    
              <p>The Owner allows Users to upload, share or provide their own content to this Application. By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights. Further insights regarding acceptable content can be found inside the section of these Terms which detail the acceptable uses.</p>
              <p className={classes.fontW400}>Rights regarding content provided by Users</p>
               <p>Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required. To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application. Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.</p>
              <p className={classes.fontW400}>Liability for provided content</p>
               <p>Users are solely liable for any content they upload, post, share, or provide through this Application. Users acknowledge and accept that the Owner does not filter or moderate such content.</p>
               <p>The removal, deletion, blocking or rectification of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement. Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.</p>
              <p className={classes.fontW400}>Removal of content from parts of this Application available through the App Store</p> 
               <p>If the reported content is deemed objectionable, it will be removed within 24 hours and the User who provided the content will be barred from using the Service.</p>
              <p className={classes.fontW400}>Access to provided content</p> 
               <p>Content that Users provide to this Application is made available according to the criteria outlined within this section.</p> 

            <p className={classes.fontW500}>Access to external resources</p>       
             <p>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability. Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>

            <p className={classes.fontW500}>Acceptable use</p>       
             <p>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law. Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights. Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />violate laws, regulations and/or these Terms;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />infringe any third-party rights;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />considerably impair the Owner’s legitimate interests;</p>
              <p><ArrowRightIcon style={{fontSize:'medium'}} />offend the Owner or any third party.</p>
            
            <p className={classes.fontW500}>Purchase via app store</p>  
             <p>This Application or specific Products available for sale on this Application must be purchased via a third-party app store. To access such purchases, Users must follow the instructions provided on the relevant online store (such as "Apple App Store" or "Google Play"), which may vary depending on the particular device in use. Unless otherwise specified, purchases done via third-party online stores are also subject to such third-parties’ terms and conditions, which, in case of any inconsistency or conflict, shall always prevail upon these Terms. Users purchasing through such third-party online stores must therefore read such terms and conditions of sale carefully and accept them.</p>

            <p className={classes.fontW500}>Contract duration</p>  
             <p className={classes.fontW400}>Subscriptions</p>
              <p>Subscriptions allow Users to receive a Product continuously or regularly over a determined period of time. Paid subscriptions begin on the day the payment is received by the Owner. In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.</p>
             <p className={classes.fontW400}>Subscriptions handled via Apple ID</p>
              <p>Users may subscribe to a Product using the Apple ID associated with their Apple App Store account by using the relevant process on this Application. When doing so, Users acknowledge and accept that</p>
               <p><ArrowRightIcon style={{fontSize:'medium'}} />any payment due shall be charged to their Apple ID account;</p>
               <p><ArrowRightIcon style={{fontSize:'medium'}} />subscriptions are automatically renewed for the same duration unless the User cancels at least 24 hours before the current period expires;</p>
               <p><ArrowRightIcon style={{fontSize:'medium'}} />any and all fees or payments due for renewal will be charged within 24-hours before the end of the current period;</p>
               <p><ArrowRightIcon style={{fontSize:'medium'}} />subscriptions can be managed or cancelled in the Users’ Apple App Store account settings.</p>
             <p className={classes.fontW400}>Termination</p>
              <p>Recurring subscriptions may be terminated at any time by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside this Application.</p>
            
            <h3>Common provisions</h3> 
            <p className={classes.fontW500}>No Waiver</p>  
              <p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
            <p className={classes.fontW500}>Service interruption</p>  
              <p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately. Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law. Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
            <p className={classes.fontW500}>Service reselling</p>  
              <p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
            <p className={classes.fontW500}>Privacy policy</p>  
              <p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Application.</p>
            <p className={classes.fontW500}>Intellectual property rights</p>  
              <p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property. All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
            <p className={classes.fontW500}>Changes to these Terms</p>  
              <p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes. Such changes will only affect the relationship with the User for the future. The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement. The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner. If required by applicable law, the Owner will specify the date by which the modified Terms will enter into force.</p>
            <p className={classes.fontW500}>Assignment of contract</p>  
              <p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly. Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
            <p className={classes.fontW500}>Contacts</p>  
              <p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>
            <p className={classes.fontW500}>Severability</p>  
              <p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>       
            <h3 style={{textAlign:'center', borderBottom:'1px dotted #DFDFDF', padding:'20px'}}> </h3>
            <p>Latest update: June 07, 2021</p>
            <p>ProEditor hosts this content and only collects the Personal Data strictly necessary for it to be provided.</p>
   
         </Grid>
         <Grid item sm={1} xm={0}></Grid>  
       </Grid>
      </div> 
      <Footer/>
    </div>
  );
}