import React, {useState, useEffect} from 'react';  
var QRCode = require('qrcode.react');
 

export default function QrCode(props) {
        
    const [id,setID] = useState("");
    useEffect(() => {    
        setID(props.id);
        console.log(props.id);
     
    }, [props])

    return (
        <div style={{textAlign:'center'}}>
         <p>Sign in with QR code by scanning with App</p>
         <QRCode value={id} />
        </div>
    );
}