import React, {useState, useEffect} from 'react'; 
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from "react-router-dom"; 
import clsx from 'clsx';  
import Drawer from '@material-ui/core/Drawer';  
import List from '@material-ui/core/List'; 
import Divider from '@material-ui/core/Divider';  
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Logo from '../images/logo.png';

// const drawerWidth = 240;

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 2,
    cursor: 'pointer',
  }, 
  logo_name:{
    textAlign:'left',
    marginTop:'auto',
    marginBottom:'auto',
    fontWeight:'700',
    fontSize:'26px'
  }
}));

const useStyles2 = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${0}px)`,     
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,      
    },
    title: {
      flexGrow: 1,      
    },
    hide: {
      display: 'none',
    },
    drawer: {
     // width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
     // width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: 0,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
  }));

export default function NavBar() {
  const [isLogin, loadLogin] = useState('false');  
  useEffect(() => {
    if(localStorage.getItem('login')){
      loadLogin(localStorage.getItem('login'));      
    }else{
      console.log("not login");
    }      
     
  }, [])

  let history =useHistory();

  const classes1 = useStyles1();
  const matches = useMediaQuery('(min-width:600px)');

  const classes2 = useStyles2();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Sign_in = () =>{
    history.push("/sign_in");
  }

  const My_account = () =>{
    history.push("/my_account");
  }

  const Price_info = ()=>{
    history.push("/price_info");
  }

  const Contact_us = ()=>{
    history.push("/contact_us");
  }

  const Policy = ()=>{
    history.push("/policy");
  }



  const GoHome = ()=>{
    history.push("/");
  }

  return (
    <div className={classes1.root}>
     {matches===true?
      <AppBar className={'myNav'} position="static" >
        <Toolbar>          
          <Typography variant="h6" className={classes1.title} onClick={GoHome}>           
            <div style={{display:'flex'}}><img src={Logo} style={{maxWidth:'70px', padding:'20px'}}/> <span className={classes1.logo_name}>ProEditor</span></div>
          </Typography>    
          <Button color="inherit" onClick={Policy}>Policy</Button>      
          {/* <Button color="inherit" onClick={Price_info}>Price</Button> */}
          <Button color="inherit" onClick={Contact_us}>Contact Us</Button>           
          {isLogin == 'true'?<Button color="inherit" onClick={My_account}>My Account</Button>:<Button color="inherit" onClick={Sign_in}>Login</Button>}
        </Toolbar>
      </AppBar> : 
      <div>
        {/* <CssBaseline /> */}
        <AppBar  position="fixed"  className={clsx(classes2.appBar, {
            [classes2.appBarShift]: open,
        })}>
        <Toolbar className={'myNav'}>
            <Typography variant="h6" noWrap className={classes2.title}>
            ProEditor
            </Typography>
            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleDrawerOpen} className={clsx(open && classes2.hide)}>
            <MenuIcon />
            </IconButton>
        </Toolbar>
        </AppBar>
        <main className={clsx(classes2.content, {[classes2.contentShift]: open,})}>
          <div className={classes2.drawerHeader} />         
        </main>
        <Drawer className={classes2.drawer} variant="persistent" anchor="right" open={open} classes={{paper: classes2.drawerPaper,}}>
          <div className={classes2.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
          </div>
        <Divider />
          <List> 
              <ListItem onClick={Policy} button key="Price">
                  <ListItemIcon> <InboxIcon /> </ListItemIcon>
                  <ListItemText primary={"Policy"} />
              </ListItem>
              <ListItem onClick={Contact_us} button key="Contact Us">
                  <ListItemIcon> <InboxIcon /> </ListItemIcon>
                  <ListItemText primary={"Contact Us"} />
              </ListItem>
          </List>
        <Divider />
          <List>               
              {isLogin == 'true'?
                <ListItem onClick={My_account} button key="Log in">
                    <ListItemIcon> <InboxIcon /> </ListItemIcon>
                    <ListItemText primary={"My Account"} />
                </ListItem>:
                <ListItem onClick={Sign_in} button key="Log in">
                    <ListItemIcon> <InboxIcon /> </ListItemIcon>
                    <ListItemText primary={"Log in"} />
                </ListItem>            
              }               
          </List>
        </Drawer>
       </div>
     }
    </div>
  );
}