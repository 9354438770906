import React, {useState, useEffect} from 'react';
import NavBar from '../components/Navbar';
import Album from '../components/Album'; 
import AWS from 'aws-sdk';
import ImagePop from '../components/ImagePop';
import Footer from '../components/Footer';


AWS.config.update({
    accessKeyId: 'AKIA6GU5XYANVL6WZSEF',
    secretAccessKey: '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',
    region: 'us-east-2',
});
const s3 = new AWS.S3();
  
// const params = {
//     Bucket: 'proeditor-home',
//     Delimiter: '',
//     Prefix: 'hana/',
// };

//https://proeditor-home.s3.us-east-2.amazonaws.com/hana/
const AlbumPage = () =>{
  
    let myDir = localStorage.getItem('userId');  
    const params = {
        Bucket: 'proeditor-home',
        Delimiter: '',
        Prefix: 'user/' + myDir + '/',
    }; 
    const [listFiles, setListFiles] = useState([]); 
    const [isLogin, setLogin] = useState('false');
    const [openDialog, setOpenDialog] = useState(false)
    const [sendURL, setSendURL] = useState('')
    

    const openImage = (e, url) => {
        setOpenDialog(e)
        setSendURL(url)
    }
     
    useEffect(()=>{ 
        setLogin(localStorage.getItem('login'));
        if(localStorage.getItem('login') == 'true'){
           s3.listObjectsV2(params, (err, data)=>{
            setListFiles(data.Contents.reverse());
            console.log("S3",data.Contents);
           });  
        }        
    },[]);  

    return(
        <>
            <div>
                <NavBar/> 
                {isLogin =='true'?<Album data={listFiles} openDialog={openImage} />:<div/>}   
            </div>
            <div>
                <ImagePop openDialog={openDialog} setOpenDialog={setOpenDialog} sendURL={sendURL} />    
            </div>  
            <Footer/>
        </>
    );
}
export default AlbumPage
